.cardContainer {
  height: 100%;
  &.container {
    height: calc(100% - 50px);
  }
}

.card {
  @apply block p-5 rounded-lg bg-white my-1;
  &.fill {
    height: 100%;
    overflow: auto;
  }
}
