.dashboard {
  display: grid;
  gap: 15px;
  height: calc(100vh - 120px);
  & .carouselItem {
    grid-area: carousel;
  }
  & .listOfObjectivesItem {
    grid-area: listofobjectives;
  }
  & .eventsItem {
    grid-area: events;
  }
  & .optionsItem {
    grid-area: options;
  }
  grid-template-areas:
    'carousel'
    'options'
    'listofobjectives'
    'events';
  grid-template-columns: auto;
  grid-template-rows: auto auto auto 1fr;
  @screen xl {
    @apply gap-5;
    grid-template-areas:
      'carousel events'
      'options events'
      'listofobjectives events';
    grid-template-columns: 1fr minmax(200px, 430px);
    grid-template-rows: auto auto 1fr;
  }
}

.eventsContainer {
  // min-height: calc(100vh - 108px);
}

@media (max-width: 1280px) {
  .eventsContainer {
    // @apply min-h-full;
  }
}
