.sideIcon {
  @apply h-5 w-5 mr-3 text-gray-500;
}
.sideItem {
  @apply inline-flex w-full
  items-center 
  text-base 
  py-4 px-6 h-12 
  overflow-hidden
  text-gray-500 
  text-ellipsis whitespace-nowrap 
  rounded-lg 
 hover:bg-blue-50 transition duration-300 ease-in-out;
  > svg {
    @apply text-gray-500;
  }
}
.sideItemSelected {
  @apply bg-blue-500
  text-white
  hover:bg-blue-500
  hover:text-white;
  > svg {
    color: currentColor;
  }
}
.toggleSideNavButton {
  @apply hover:text-blue-600 hover:cursor-pointer 
  rounded-full 
  hover:bg-gray-200 
  transition-all
  w-fit p-2 
  absolute top-5 
  right-2 
  active:scale-95;
}
.sideNavCollapsed {
  width: 90px;
  & .toggleSideNavButton {
    @apply relative top-0 right-0 my-3;
  }
  & ul {
    padding: 0px;
    p {
      display: none;
    }
    div > a {
      @apply transition-all px-0 flex justify-center items-center;
      span {
        display: none;
      }
      svg {
        @apply transition-all  mr-0;
      }
    }
  }
}
