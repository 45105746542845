.proggressBar {
  height: 3px;
  width: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  @apply bg-blue-100;
  .progressBarValue {
    width: 100%;
    height: 100%;
    animation: indeterminateAnimation 1s infinite linear;
    transform-origin: 0% 50%;
    border-radius: 50px;
    @apply bg-blue-500;
  }
}

@keyframes indeterminateAnimation {
  0% {
    transform: translateX(0) scaleX(0);
  }
  40% {
    transform: translateX(0) scaleX(0.4);
  }
  100% {
    transform: translateX(100%) scaleX(0.5);
  }
}
