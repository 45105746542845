.textInput {
  @apply block
  w-full
  font-normal
  text-gray-700
  bg-white
  bg-clip-padding
  border border-solid border-gray-300
  rounded
  transition
  ease-in-out
  m-0
  focus:text-gray-700 
  focus:bg-white 
  focus:border-blue-600 focus:outline-none;
}

.textInputXsm {
  @apply textInput text-sm;
  padding: 0.25rem 0.75rem 0.1rem 0.75rem;
  height: calc(2rem + 5px) !important;
}

.textInputSm {
  @apply textInput text-sm;
  padding: 0.5rem 0.75rem;
  height: calc(2.5rem + 2px) !important;
}

.textInputMd {
  @apply textInput text-base;
  padding: 0.75rem 0.75rem;
  height: calc(3rem + 2px) !important;
}

.labelInputSm {
  @apply inline-block mb-2 text-gray-400 text-sm;
  padding: 0.65rem 0.75rem !important;
  height: calc(2.5rem + 2px) !important;
}

.labelInputMd {
  @apply inline-block mb-2 text-gray-400 text-base;
  padding: 0.75rem 0.75rem !important;
  height: calc(3rem + 2px) !important;
}

.textArea {
  @apply block
  w-full
  px-2
  py-1.5
  text-sm
  font-normal
  text-gray-700
  bg-white bg-clip-padding
  border border-solid border-gray-300
  rounded
  transition
  ease-in-out
  m-0
  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none;
}

.textAreaXsm {
  @apply textArea text-sm;
}

.textAreaSm {
  @apply textArea text-sm;
}

.textAreaMd {
  @apply textArea text-base;
}
