.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: all 0.4s;
}
.fade-enter-done {
  opacity: 1;
}

.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: all 0.2s;
}
.fade-exit-done {
  opacity: 0;
}
