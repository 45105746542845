@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
}
html {
  @apply scroll-smooth;
}

.nav-tabs .nav-link.active {
  @apply text-blue-500 border-blue-500;
}

select {
  @apply appearance-none block
  w-full
  px-2
  py-1
  text-sm
  font-normal
  text-gray-700
  bg-white bg-clip-padding bg-no-repeat
  border border-solid border-gray-300
  rounded
  transition
  ease-in-out
  m-0
  h-8
  focus:text-gray-700 
  focus:bg-white 
  focus:border-blue-600 
  focus:outline-none;
}

thead {
  @apply bg-gray-50;
}

th {
  @apply font-medium text-sm p-1;
}

td {
  @apply font-normal text-sm p-2;
}

svg {
  stroke-width: 1.5;
}
button {
  position: relative;
}
button[title]:hover::after {
  content: attr(title);
  position: absolute;
  top: -28px;
  @apply bg-gray-700 px-2 py-1 text-gray-200 rounded;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  @apply bg-gray-50;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-gray-300;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  @apply bg-gray-400;
}
